<template>
	<v-dialog
		v-model="showModal"
		max-width="450px"
		v-if="showModal"
		@click:outside="close()"
	>
		<v-card class="pa-3 mb-0 d-flex flex-column delete-modal">
			<v-card-title class="ma-3 d-flex flex-column">
				<img
					width="200px"
					:src="require('@/../public/unosearch_logo.svg')"
				/>
			</v-card-title>
			<v-card-text>
				<v-row class="ma-3">
					<p class="text-center text-primary text-h6">
						Apply coupon code to get discount on your purchase
					</p>
				</v-row>
				<v-row align="center" justify="center">
					<div class="ma-2 text-subtitle-1">
						Enter your coupon code
					</div>
					<v-text-field
						dense
						@input="applyCoupon()"
						v-model="coupon"
						:disabled="couponIsDisabled"
						outlined
						placeholder="Enter your coupon code"
					></v-text-field>
				</v-row>

				<hr />
				<v-row class="ma-2" align="center" justify="space-between">
					<div class="text-subtitle-2">Total</div>
					<div class="text-h6" v-if="discountedAmount">
						<strike>{{ initialAmount }}</strike>
						{{ discountedAmount }} +18%GST
					</div>
					<div class="text-h6" v-else>
						{{ initialAmount }} +18%GST
					</div>
				</v-row>
				<hr />
				<v-btn
					@click="
						couponInput && discountedAmount == null
							? validCoupon()
							: payment()
					"
					width="100%"
					class="mt-3 primary"
					>{{ buttonText }}
				</v-btn>
			</v-card-text></v-card
		></v-dialog
	>
</template>

<script>
export default {
	props: {
		planName: {
			type: String,
			required: true,
		},
		planPeriod: {
			type: String,
			required: true,
		},
		projectId: {
			type: String,
			required: false,
		},
	},
	data() {
		return {
			plan: null,
			initialAmount: null,
			discountedAmount: null,
			coupon: null,
			buttonText: 'Pay',
			couponIsDisabled: false,
			selectedCoupon: null,
			showModal: true,
		}
	},
	created() {
		const projectId = this.projectId
			? this.projectId
			: this.$store.state.app.projectId

		this.axios({
			method: 'get',
			url: `/${projectId}/pricing`,
			params: {
				name: this.planName,
				period: this.planPeriod,
			},
		})
			.then((res) => {
				if (res.status === 404) {
					console.log(404)
				}

				if (res.status == 200) {
					this.plan = res.data.plan
					if (this.plan.periodical == 'Monthly') {
						this.initialAmount = this.plan.price
					} else if (this.plan.periodical == 'Quaterly') {
						this.initialAmount = this.plan.price * 3
					} else if (this.plan.periodical == 'Half Yearly') {
						this.initialAmount = this.plan.price * 6
					} else if (this.plan.periodical == 'Annually') {
						this.initialAmount = this.plan.price * 12
					}
				}
			})
			.catch((error) => {
				let errors = error.response.data
				this.alert.message = errors.message
			})
	},
	computed: {
		couponInput: {
			get() {
				return this.coupon != null && this.coupon != '' ? true : false
			},
		},
	},

	methods: {
		close() {
			this.$emit('closeModal')
		},
		applyCoupon() {
			if (this.couponInput) {
				this.buttonText = 'Apply Coupon'
			} else {
				this.buttonText = 'Pay'
			}
		},
		validCoupon() {
			const projectId = this.projectId
				? this.projectId
				: this.$store.state.app.projectId
			this.axios({
				method: 'get',
				url: `/${projectId}/coupon/apply`,
				params: {
					coupon: this.coupon,
					plan: this.plan.id,
				},
			}).then((response) => {
				if (response.status == 200 && response.data) {
					const { coupon } = response.data
					if (coupon.id) {
						this.selectedCoupon = coupon
						this.couponIsDisabled = true
						this.discountedAmount =
							this.initialAmount - coupon.discount
					} else {
						this.coupon = null
					}
					this.buttonText = 'Pay'
				}
			})
		},
		payment() {
			const projectId = this.projectId
				? this.projectId
				: this.$store.state.app.projectId
			const payload = {
				plan: this.plan.id,
				coupon: this.selectedCoupon ? this.selectedCoupon.id : null,
			}
			this.axios({
				method: 'post',
				url: `/${projectId}/payment`,
				data: payload,
			}).then((response) => {
				if (response.status == 200) {
					const { payment } = response.data
					this.$emit('checkout', payment)
					this.showModal = false
				}
			})
		},
	},
}
</script>
