<template>
	<v-container fluid>
		<v-row class="d-flex justify-center text-center">
			<v-col lg="8">
				<h1>Pricing</h1>
				<span>
					Your Plan. Your Savings. Save time and earn money by
					<br />reaching the top positions in Local Pack.
				</span>
			</v-col>
			<v-col class="pa-4 ma-5" lg="12">
				<v-tabs
					v-model="tab"
					background-color="transparent"
					color="basil"
					grow
				>
					<v-tab
						v-for="item in periods"
						:key="item"
						@click="selectedPeriod(item)"
					>
						{{ item }}
					</v-tab>
					<v-tab-item v-for="n in 4" :key="n">
						<v-container fluid>
							<v-row>
								<v-col class="d-flex justify-space-between">
									<v-card
										class="pa-5"
										v-for="item in packages"
										:key="item.id"
									>
										<h2>{{ item.name }}</h2>
										<div class="ma-5 text-center">
											<span>
												<strong class="price"
													>₹{{ item.price }}</strong
												>
												/month</span
											>
											<p>excl. of all taxes</p>
										</div>
										{{ item.description }}
										<div
											class="ma-5"
											v-for="feature in item.features"
											:key="feature"
										>
											<p>
												<v-icon
													class="float-left"
													color="rgb(0 0 128)"
													>mdi-check</v-icon
												>
												{{ feature }}
											</p>
										</div>
										<v-btn
											color="primary"
											@click="planSelected(item)"
											>Select Plan</v-btn
										>
									</v-card>
								</v-col>
							</v-row>
						</v-container>
					</v-tab-item>
				</v-tabs>
			</v-col>
		</v-row>
		<Coupon
			v-if="showCouponModal"
			@closeModal="
				() => {
					this.showCouponModal = !this.showCouponModal
				}
			"
			@checkout="checkout"
			:planName="selectedPlan.name"
			:planPeriod="selectedPlan.periodical"
		/>
		<v-dialog v-model="verificationModal" max-width="600px">
			<v-card class="pa-6 mb-0 d-flex flex-column success-modal">
				<div class="d-flex flex-row align-center">
					<span
						v-if="verify"
						class="mdi mdi-checkbox-marked-circle icon pr-2"
					></span>
					<span
						v-else
						class="mdi mdi-close-circle-outline icon pr-2"
					></span>

					<h1 v-if="verify" class="heading">Payment Successful</h1>
					<h1 v-else class="heading">Payment Unsuccessful</h1>
				</div>
				<div class="d-flex flex-row justify-end">
					<v-btn
						class="mt-4 align-self-end mr-3"
						outlined
						color="#4daf51"
						@click="
							() => {
								this.$router.push({
									name: 'dashboard',
								})
							}
						"
						>Move to dashboard</v-btn
					>
					<v-btn
						class="mt-4"
						outlined
						color="indigo"
						@click="verificationModal = false"
						>Close</v-btn
					>
				</div>
			</v-card>
		</v-dialog>
	</v-container>
</template>

<script>
import Coupon from '../../components/Coupon.vue'

export default {
	/* eslint-disable */
	components: {
		Coupon,
	},
	data() {
		return {
			tab: null,
			verificationModal: false,
			verify: null,
			periods: ['Monthly', 'Quaterly', 'Half Yearly', 'Annually'],
			packages: [],
			selectedPlan: null,
			period: null,
			showCouponModal: false,
		}
	},
	created() {
		this.selectedPeriod('Monthly')
	},

	// computed: {
	// 	couponInput: {
	// 		get() {
	// 			return this.coupon != null && this.coupon != '' ? true : false
	// 		},
	// 	},
	// },
	methods: {
		// applyCoupon() {
		// 	if (this.couponInput) {
		// 		this.buttonText = 'Apply Coupon'
		// 	} else {
		// 		this.buttonText = 'Pay'
		// 	}
		// },
		// validCoupon() {
		// 	const projectId = this.$store.state.app.projectId
		// 	this.axios({
		// 		method: 'get',
		// 		url: `/${projectId}/coupon/apply`,
		// 		params: {
		// 			coupon: this.coupon,
		// 		},
		// 	}).then((response) => {
		// 		if (response.status == 200 && response.data) {
		// 			const { coupon } = response.data
		// 			if (coupon.id) {
		// 				this.selectedCoupon = coupon
		// 				this.couponIsDisabled = true
		// 				this.discountedAmount =
		// 					this.initialAmount - coupon.discount
		// 				this.buttonText = 'Pay'
		// 			} else {
		// 				this.coupon = null
		// 				this.couponMessage = `Coupon Code is not valid,please try another or proceed to payment`
		// 			}
		// 		}
		// 	})
		// },
		// payment() {
		//
		// 	const payload = {
		// 		plan: this.selectedPlan.id,
		// 		coupon: this.selectedCoupon ? this.selectedCoupon.id : null,
		// 	}
		// 	console.log(payload)
		// 	this.axios({
		// 		method: 'post',
		// 		url: `/${projectId}/payment`,
		// 		data: payload,
		// 	})
		// },
		paymentFailure(payment) {
			console.log('failed', payment)
			alert('Payment Failed')
		},
		verifySignature(payment) {
			const projectId = this.$store.state.app.projectId
			this.axios({
				method: 'post',
				url: `/${projectId}/payment/verify`,
				data: payment,
			}).then((response) => {
				this.verificationModal = true
				if (response.status == 200) {
					this.verify = response.data.verify
				}
			})
		},
		checkout(paymentDetails) {
			this.showCouponModal = !this.showCouponModal
			let options = {
				key: process.env.VUE_APP_RAZORPAY_KEY_ID, // Enter the Key ID generated from the Dashboard
				amount: paymentDetails.amount, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
				currency: 'INR',
				name: 'UnoSearch',
				description: `Payment `,
				image: require('../../../public/unosearch_logo.svg'),
				order_id: paymentDetails.order, //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
				handler: (res) => this.verifySignature(res),

				theme: {
					color: '#3399cc',
				},
			}
			const rzp1 = new Razorpay(options)
			rzp1.on('payment.failed', (res) => this.paymentFailure(res))
			rzp1.open()
		},
		planSelected(plan) {
			this.selectedPlan = plan
			this.showCouponModal = true
		},

		selectedPeriod(period) {
			this.period = period
			const projectId = this.$store.state.app.projectId
			this.axios({
				method: 'get',
				url: `/${projectId}/pricing/period`,
				params: {
					period,
				},
			})
				.then((res) => {
					if (res.status === 404) {
						console.log(404)
					}
					if (res.status == 200) {
						this.packages = res.data.data
					}
				})
				.catch((error) => {
					let errors = error.response.data
					this.alert.message = errors.message
				})
		},
	},
}
</script>

<style scoped>
.price {
	font-size: 30px;
}
.heading {
	color: green;
	font-weight: 300;
}
.success-modal .heading {
	color: #4daf51;
	font-weight: 400;
	font-size: 1.5rem;
}
.success-modal .icon {
	font-size: 1.7rem;
	color: #4daf51;
}
</style>
