var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',{staticClass:"d-flex justify-center text-center"},[_c('v-col',{attrs:{"lg":"8"}},[_c('h1',[_vm._v("Pricing")]),_c('span',[_vm._v(" Your Plan. Your Savings. Save time and earn money by "),_c('br'),_vm._v("reaching the top positions in Local Pack. ")])]),_c('v-col',{staticClass:"pa-4 ma-5",attrs:{"lg":"12"}},[_c('v-tabs',{attrs:{"background-color":"transparent","color":"basil","grow":""},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_vm._l((_vm.periods),function(item){return _c('v-tab',{key:item,on:{"click":function($event){return _vm.selectedPeriod(item)}}},[_vm._v(" "+_vm._s(item)+" ")])}),_vm._l((4),function(n){return _c('v-tab-item',{key:n},[_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{staticClass:"d-flex justify-space-between"},_vm._l((_vm.packages),function(item){return _c('v-card',{key:item.id,staticClass:"pa-5"},[_c('h2',[_vm._v(_vm._s(item.name))]),_c('div',{staticClass:"ma-5 text-center"},[_c('span',[_c('strong',{staticClass:"price"},[_vm._v("₹"+_vm._s(item.price))]),_vm._v(" /month")]),_c('p',[_vm._v("excl. of all taxes")])]),_vm._v(" "+_vm._s(item.description)+" "),_vm._l((item.features),function(feature){return _c('div',{key:feature,staticClass:"ma-5"},[_c('p',[_c('v-icon',{staticClass:"float-left",attrs:{"color":"rgb(0 0 128)"}},[_vm._v("mdi-check")]),_vm._v(" "+_vm._s(feature)+" ")],1)])}),_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.planSelected(item)}}},[_vm._v("Select Plan")])],2)}),1)],1)],1)],1)})],2)],1)],1),(_vm.showCouponModal)?_c('Coupon',{attrs:{"planName":_vm.selectedPlan.name,"planPeriod":_vm.selectedPlan.periodical},on:{"closeModal":() => {
				this.showCouponModal = !this.showCouponModal
			},"checkout":_vm.checkout}}):_vm._e(),_c('v-dialog',{attrs:{"max-width":"600px"},model:{value:(_vm.verificationModal),callback:function ($$v) {_vm.verificationModal=$$v},expression:"verificationModal"}},[_c('v-card',{staticClass:"pa-6 mb-0 d-flex flex-column success-modal"},[_c('div',{staticClass:"d-flex flex-row align-center"},[(_vm.verify)?_c('span',{staticClass:"mdi mdi-checkbox-marked-circle icon pr-2"}):_c('span',{staticClass:"mdi mdi-close-circle-outline icon pr-2"}),(_vm.verify)?_c('h1',{staticClass:"heading"},[_vm._v("Payment Successful")]):_c('h1',{staticClass:"heading"},[_vm._v("Payment Unsuccessful")])]),_c('div',{staticClass:"d-flex flex-row justify-end"},[_c('v-btn',{staticClass:"mt-4 align-self-end mr-3",attrs:{"outlined":"","color":"#4daf51"},on:{"click":() => {
							this.$router.push({
								name: 'dashboard',
							})
						}}},[_vm._v("Move to dashboard")]),_c('v-btn',{staticClass:"mt-4",attrs:{"outlined":"","color":"indigo"},on:{"click":function($event){_vm.verificationModal = false}}},[_vm._v("Close")])],1)])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }